import LoginContainer from "../components/login/LoginContainer";
import { Container } from "@mui/material";
import { Color } from "../types/enums";

const LoginPage = () => {
  return (
    <Container maxWidth={false} sx={{ minHeight: "100vh", backgroundColor: Color.MINT }}>
      <LoginContainer />
    </Container>
  );
};

export default LoginPage;
