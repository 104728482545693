import { DataGridPremium, GridRowParams } from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-license-pro";
import useStudentTable from "../../hooks/dashboard/useStudentTable";
import useStudentTableColumns from "../../hooks/dashboard/useStudentTableColumns";
import { Box, Stack } from "@mui/material";
import StudentDashboardDialog from "./StudentDashboardDialog";
import { useRecoilValue } from "recoil";
import { staffSideBarRouterAtom } from "../../../shared/recoil/routerAtoms";
const KEY = process.env.REACT_APP_DATATABLE_KEY ?? "";
LicenseInfo.setLicenseKey(KEY);

const StudentTable = () => {
  const { onRowClick, studentRows, studentDialogOpen, setStudentDialogOpen } = useStudentTable();
  const { staffColumns } = useStudentTableColumns();
  const navOpen = useRecoilValue<boolean>(staffSideBarRouterAtom);

  return (
    <>
      {studentRows && (
        <Box sx={{ height: "calc(100vh - 250px)" }} id="app-manager-table">
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: navOpen ? "calc(100vw - 250px)" : "calc(100vw - 96px)",
            }}
          >
            <DataGridPremium
              rows={studentRows}
              columns={staffColumns}
              className="counselor-table"
              disableColumnMenu
              disableColumnResize
              onRowClick={(params: GridRowParams) => onRowClick(params.row)}
              getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd")}
              getRowId={(row) => row.id}
              sx={{
                fontSize: 12,
                border: "none",
                "& .MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "center",
                },
              }}
              getRowHeight={() => "auto"}
              slots={{
                noRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    No result found
                  </Stack>
                ),
              }}
            />
          </Box>
        </Box>
      )}
      <StudentDashboardDialog open={studentDialogOpen} setOpen={setStudentDialogOpen} />
    </>
  );
};

export default StudentTable;
