import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { Dispatch, useEffect, useState } from "react";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import { Collection, FunctionName, PageRoute } from "../../../shared/types/enums";
import CareerPlanMessage from "./CareerPlanMessage";
import { AlmaSidebarStarter, ChatGPTEntry, GoalRecord } from "../../../shared/types/types";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useAddFSDoc from "../../../shared/hooks/db/useAddFSDoc";
import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { goalToEditAtom } from "../../recoil/goalAtoms";

type Props = {
  setAlmaStarters: Dispatch<React.SetStateAction<AlmaSidebarStarter[]>>;
  chatHistory: ChatGPTEntry[];
  almaStarters: AlmaSidebarStarter[];
};

const ManageGoalsContainer = ({ setAlmaStarters }: Props) => {
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const [goalText, setGoalText] = useState("");
  const { addFSDoc } = useAddFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const navigate = useNavigate();
  const [goalToEdit, setGoalToEdit] = useRecoilState(goalToEditAtom);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (goalToEdit) {
      setGoalText(goalToEdit.goal);
    }
  }, [goalToEdit]);

  const handleGoalTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGoalText(e.target.value);
    setAlmaStarters((prev) => [
      {
        label: "Get Feedback",
        prompt: `${e.target.value}`,
        systemContext: prev[0].systemContext,
        validation: prev[0].validation,
        promptPrefix: prev[0].promptPrefix,
      },
    ]);
  };

  const handleSave = async () => {
    if (!loggedInStudent || !goalText.trim()) return;

    setLoading(true);
    try {
      if (goalToEdit) {
        updateFSDoc({
          col: Collection.GOALS,
          id: goalToEdit.id,
          data: { goal: goalText },
        });
      } else {
        const results = await addFSDoc<GoalRecord>({
          col: Collection.GOALS,
          data: { goal: goalText, state: "active", studentId: loggedInStudent.id },
        });
        await fetchData({
          functionName: FunctionName.CREATE_GOAL_TITLE,
          payload: { goalRecord: results },
        });
      }
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setGoalToEdit(null);
    navigate(PageRoute.STUDENT_PROFILE);
  };

  return (
    <Box
      sx={{
        height: mobile ? "calc(100vh - 120px)" : "100vh",
        overflowY: "scroll",
        backgroundColor: "#FCFBF5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          mx: mobile ? 1 : 4,
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h4" sx={{ fontSize: 32, color: "#171918" }}>
            Set up a SMART Goal
          </Typography>
          <Typography variant="body1" sx={{ color: "#171918", mt: 1 }}>
            SMART goals are Specific, Measurable, Achievable, Relevant, and Time-Bound objectives that will get you
            closer to your post-secondary dreams.
          </Typography>

          <TextField
            multiline
            rows={6}
            fullWidth
            value={goalText}
            onChange={handleGoalTextChange}
            placeholder="Example: I will expand my knowledge of the medical field by learning about three different healthcare careers and their educational pathways by the end of the semester."
            variant="outlined"
            sx={{
              mt: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: 3, // Set border radius for the entire input container
                "& fieldset": {
                  borderColor: "#CACECC", // Change border color
                },
                "&:hover fieldset": {
                  borderColor: "#CACECC", // Prevent border color change on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#CACECC", // Prevent border color change when focused
                },
              },
              "& .MuiOutlinedInput-inputMultiline": {
                padding: "12px", // Add padding to prevent the text/placeholder from being cut off
              },
            }}
            InputProps={{
              style: { backgroundColor: "white" }, // Set the fill color directly here
            }}
          />

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button variant="outlined" color="primary" sx={{ mr: 1 }} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 1 }}
              disabled={loading || !goalText.trim()}
              onClick={handleSave}
            >
              {loading ? "Saving..." : "Save SMART Goal"}
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ManageGoalsContainer;
